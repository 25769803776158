export default function getShopsList(items = []) {
  return items.map(
    ({
      campaign: name,
      campaignID: id,
      deliveryTime,
      deliveryCosts,
      offers,
      price,
      price_original: previousPrice,
    }) => ({
      deliveryCosts,
      deliveryTime,
      id,
      name,
      price,
      previousPrice,
      url: offers[0]?.url,
    })
  );
}
